
import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import { ImSearch } from 'react-icons/im';
import CustomInput from './CustomInput';
import { Link } from 'react-router-dom';

// Images
import sliderImg3 from "../../images/slider/slide3.jpg"

const MainSlider = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <Slider {...settings} className="tt-slider slider-one slider-two owl-btn-1 slider-sp0">
        <div className="slider-item">
          <div className="slider-thumb">
            <img src={sliderImg3} alt="" />
          </div>
          <div className="slider-content">
            <div className="container">
              <div className="content-inner">
                <h4 className="title">India's Largest Online<br />Corporate Services.</h4>
                <h6 className="sub-title">All your Business Solutions under one platform</h6>
                <div className="search-bar">
                  <CustomInput />
                  <button className='btn button-sm radius-xl' style={{ fontSize: '13px'}}><ImSearch /></button>
                </div>
				    <h6 style={{marginTop: '2.5%', marginBottom: '10px', fontSize: '15px' }}>Popular Services:</h6>
                <div className="view-project-container">
                  <button className="btn button-sm radius-md" style={{backgroundColor:'#675fff', fontSize: '10px', marginBottom: '5px', }} to={"/portfolio-grid-2"}>Pvt Ltd Company Registration</button>
                  <Link className="btn button-sm radius-md" style={{ backgroundColor:'#675fff', fontSize: '10px', marginBottom: '5px' }} to={"/portfolio-grid-2"}>FSSAI License</Link>
				    <Link className="btn button-sm radius-md" style={{ backgroundColor:'#675fff', fontSize: '10px', marginBottom: '5px' }} to={"/portfolio-grid-2"}>GST Registration</Link>
                  <Link className="btn button-sm radius-md" style={{ backgroundColor:'#675fff', fontSize: '10px', marginBottom: '5px' }} to={"/portfolio-grid-2"}>MSME Registration</Link>
                  <Link className="btn button-sm radius-md" style={{ backgroundColor:'#675fff', fontSize: '10px', marginBottom: '5px' }} to={"/portfolio-grid-2"}>IEC Registration</Link>
                  <Link className="btn button-sm radius-md" style={{ backgroundColor:'#675fff', fontSize: '10px', marginBottom: '5px' }} to={"/portfolio-grid-2"}>ISO Registration</Link>
                  <Link className="btn button-sm radius-md" style={{ backgroundColor:'#675fff', fontSize: '10px', marginBottom: '5px' }} to={"/portfolio-grid-2"}>Trademark Registration</Link>
                  <Link className="btn button-sm radius-md" style={{ backgroundColor:'#675fff', fontSize: '10px', marginBottom: '5px' }} to={"/portfolio-grid-2"}>Trademark Assignment</Link>
                </div>

              </div>
            </div>
          </div>
        </div>
      </Slider>
    </>
  );
};

export default MainSlider;
