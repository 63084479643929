// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyC-pkipF4e61A65-UMNbC8S7bUH-VgPOH4",
    authDomain: "afmc-sj.firebaseapp.com",
    projectId: "afmc-sj",
    storageBucket: "afmc-sj.appspot.com",
    messagingSenderId: "716712035893",
    appId: "1:716712035893:web:1398ed2052834528dfd37c",
    measurementId: "G-SJWLXGB1Q5"
};
// Initialize Firebase

const app = initializeApp(firebaseConfig);
// Export firestore database
// It will be imported into your react app whenever it is needed
export const FIREBASE_DB = getFirestore(app);
