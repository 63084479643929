import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const options = {
	settings: {
		overlayColor: "rgba(0,0,0,0.9)",
		backgroundColor: "#FDC716",
		slideAnimationType: 'slide',
	},
	buttons: {
		backgroundColor: "#232eff",
		iconColor: "rgba(255, 255, 255, 1)",
	},
	caption: {
		captionColor: "#232eff",
		captionFontFamily: "Raleway, sans-serif",
		captionFontWeight: "300",
		captionTextTransform: "uppercase",
	}
};

const Footer = () => {
  return (
    <>
        <footer className="footer-white">
            <div className="footer-top bt0">
                <div className="container">
                    <div className="row">
                        <div className="col-6 col-lg-3 col-md-2 col-sm-6">
                            <div className="widget footer_widget">
                                <h5 className="footer-title">START A BUSINESS</h5>
                                <ul>
                                    <li><Link to={"/"}>Private Limited Company Registration</Link></li>
                                    <li><Link to={"about-1"}>Limited Liability Partnership</Link></li>
                                    <li><Link to={"faq-1"}>Public Limited Company Registration</Link></li>
                                    <li><Link to={"contact-1"}>One Person Company</Link></li>
                                    <li><Link to={"contact-1"}>Partnership Firm Registration</Link></li>
                                    <li><Link to={"contact-1"}>Sole Proprietorship Registration</Link></li>
                                    <li><Link to={"contact-1"}>Nidhi Company Registration</Link></li>

                                </ul>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3 col-md-3 col-sm-6">
                            <div className="widget footer_widget">
                                <h5 className="footer-title">NGO & IPR</h5>
                                <ul>
                                    <li><Link to={"/"}>NGO REGISTRATION</Link></li>
                                    <li><Link to={"blog-standard"}>Trademark Registration</Link></li>
                                    <li><Link to={"portfolio-grid-2"}>Copyright Registration</Link></li>
                                    <li><Link to={"event"}>Patent Registration</Link></li>
                                    <li><Link to={"event"}>Trademark Objection</Link></li>
                                    <li><Link to={"event"}>Trademark Assignment</Link></li>
                                    <li><Link to={"event"}>80G and 12A Registration</Link></li>
                                    <li><Link to={"event"}>Section 8 Company Registration</Link></li>

                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 col-md-2 col-sm-6">
                            <div className="widget footer_widget">
                                <h5 className="footer-title">TAX & FINANCIAL</h5>
                                <ul>
                                    <li><Link to={"services-1"}>GST Registration</Link></li>
                                    <li><Link to={"services-details"}>GST Return Filing</Link></li>
                                    <li><Link to={"company-history-1"}>TDS Return Filing</Link></li>
                                    <li><Link to={"team"}>Professional Tax Registration</Link></li>
                                    <li><Link to={"team"}>Secretarial Audit</Link></li>
                                    <li><Link to={"team"}>Due Diligence</Link></li>
                                    <li><Link to={"team"}>Income Tax Return Filing</Link></li>
                                    <li><Link to={"team"}>Business Plan</Link></li>

                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 col-md-2 col-sm-6">
                            <div className="widget footer_widget">
                            <h5 className="footer-title">COMPLIANCE</h5>
                                <ul>
                                    <li><Link to={"services-1"}>Annual Compliance of a Pvt. Ltd. Company</Link></li>
                                    <li><Link to={"services-details"}>Annual Filing for LLP</Link></li>
                                    <li><Link to={"company-history-1"}>Event Based Compliances in a Company</Link></li>
                                    <li><Link to={"team"}>Change in Object Clause</Link></li>
                                    <li><Link to={"team"}>Appointment and Resignation of Direction</Link></li>
                                    <li><Link to={"team"}>Change in Name Clause</Link></li>

                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 text-center"> © 2023 <span className="text-primary"></span>  All Rights Reserved.</div>
                    </div>
                </div>
            </div>
        </footer>
        
    </>
);
}

export default Footer
