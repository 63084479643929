import React, { useState, useEffect, useRef } from 'react';

const CustomInput = () => {
  const dataText = ["Company Registration", "GST Registration.", "NBFC Registration", "FSSAI License"];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [currentText, setCurrentText] = useState('');
  const inputRef = useRef(null); // Create a ref to access the input element

  useEffect(() => {
    let isUnmounted = false;

    const typeWriter = (text, i) => {
      if (i < text.length && !isUnmounted) {
        setCurrentText(text.substring(0, i + 1));
        setTimeout(() => {
          typeWriter(text, i + 1);
        }, 100); // Adjust the delay between characters here (in milliseconds)
      } else {
        setTimeout(() => {
          setCurrentText(''); // Clear the current text after typewriter effect completes
        }, 2000); // Adjust the delay before clearing the text and starting the next word (in milliseconds)
      }
    };

    if (currentWordIndex === 0) {
      setCurrentText(''); // Clear the current text before starting the typewriter effect
    }

    typeWriter(dataText[currentWordIndex], 0);

    return () => {
      isUnmounted = true;
    };
  }, [currentWordIndex]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % dataText.length); // Move to the next word in dataText
    }, 2000); // Adjust the delay before starting the next word (in milliseconds)

    return () => clearTimeout(timer);
  }, [currentText, dataText.length]);

  useEffect(() => {
    // Focus the input element when it's rendered
    inputRef.current.focus();
  }, []);

  return (
    <input 
      ref={inputRef} // Bind the ref to the input element
      type="text" 
      placeholder={currentText} 
      style={{
        backgroundColor: 'white', // You can change this to the desired background color
        color: 'black', // You can change this to the desired text color
        cursor: 'text' // Show the cursor in the input field
      }}
    />
  );
};

export default CustomInput;
