// src/components/Services.js
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { collection, getDocs } from 'firebase/firestore';
import { FIREBASE_DB } from '../../firebase';
import { Link } from 'react-router-dom';
import './services.css';
import { BallCanvas } from '../canvas';

const Services = () => {
  const [activeFilter, setActiveFilter] = useState('All');
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });

  useEffect(() => {
    getServices();
  }, []);

  async function getServices() {
    getDocs(collection(FIREBASE_DB, 'services')).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setServices(newData);
      setActiveFilter(newData[0]?.name || 'All');
      setSelectedService(newData.find((item) => item.name === activeFilter) || newData[0]);
    });
  }

  const handleWorkFilter = (item) => {
    setActiveFilter(item.name);
    setAnimateCard({ y: 100, opacity: 0 });

    setTimeout(() => {
      setAnimateCard({ y: 0, opacity: 1 });
      setSelectedService(item);
    }, 500);
  };

  return (
    <div className="section-area section-sp1 bg-gray">
      <div className="container">
        <h2 className="title-head">Our Services</h2>
        <div className="app__flex">
          <div className="app__work-filter-container">
            <div className="app__work-filter">
              {services.map((item, index) => (
                <div
                  key={index}
                  onClick={() => handleWorkFilter(item)}
                  className={`app__work-filter-item app__flex p-text ${
                    activeFilter === item.name ? 'item-active' : ''
                  }`}
                >
                  {item.name}
                </div>
              ))}
            </div>
          </div>

          <div className="app__work-portfolio-container">
            <motion.div
              animate={animateCard}
              transition={{ duration: 0.5, delayChildren: 0.5 }}
              className="app__work-portfolio"
            >
              {selectedService?.services?.map((item, index) => (
                <div className="app__work-item app__flex" key={index}>
                  <div className="app__work-img app__flex">
                    <BallCanvas imgUrl={item.icon} />
                  </div>

                  <div className="app__work-content app__flex">
                    <h6 className="bold-text">{item.title}</h6>
                    <p className="p-text" style={{ marginTop: 10 }}>
                      {item.price}
                    </p>
                    <Link to={`/service/${selectedService.id}`} className="service-detail-link">
                      View Details
                    </Link>
                  </div>
                </div>
              ))}
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
