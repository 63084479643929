import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sticky from 'react-stickynode';
// import '../../css/style.css'
import '../../css/style.css'
// Images
import logo from '../../images/logo.png';
import logoWhite2 from '../../images/logo-white-2.png';
import adv from '../../images/adv/adv.jpg';

class Navbar extends Component{
	
	componentDidMount() {
		
		var menuIcon = document.querySelector(".menuicon");
      	var menuLinks = document.querySelector(".menu-links");
      	var menuClose = document.getElementById("menuClose");
		
		if (menuIcon && menuLinks && menuClose) {
		menuIcon.addEventListener('click', function () {
			menuLinks.classList.add("show");
		});
		menuClose.addEventListener('click', function () {
			menuLinks.classList.remove("show");
		});

		menuIcon.addEventListener('click',function(){
			menuLinks.classList.add("show")
		})
		menuClose.addEventListener('click',function(){
			menuLinks.classList.remove("show")
		})

        // Mobile Submenu Open Close Function
		var navMenu = [].slice.call(document.querySelectorAll('.menu-links > ul > li'));
		for (var y = 0; y < navMenu.length; y++) {
			navMenu[y].addEventListener('click', function () { menuClick(this) });
		}
	
		function menuClick(current) {
			const active = current.classList.contains("open")
			navMenu.forEach(el => el.classList.remove('open'));
			
			if(active){
				current.classList.remove('open') 
				console.log("active")
			} else{
				current.classList.add('open');
				console.log("close")
			}
		}
	}
    }
	
	render(){
		return(
			<>
				{/* <!-- Header Top ==== --> */}
				<header className="header rs-nav header-2 header-transparent">
					<Sticky enabled={true} className="sticky-header navbar-expand-lg">
						<div className="menu-bar clearfix">
							<div className="container-fluid clearfix">
								{/* <!-- Header Logo ==== --> */}
								<div className="menu-logo">
									<Link to={"/"}><img src={logoWhite2} alt=""/></Link>
								</div>
								
								{/* <!-- Mobile Nav Button ==== --> */}
								<button className="navbar-toggler collapsed menuicon justify-content-end" type="button" data-toggle="collapse" data-target="#menuDropdown" aria-controls="menuDropdown" aria-expanded="false" aria-label="Toggle navigation">
									<span></span>
									<span></span>
									<span></span>
								</button>
								{/* <!-- Author Nav ==== --> */}
								{/* <div className="secondary-menu">
									<div className="header-contact">
										<h5 className="info-no"><strong>Call Us</strong> +880.762.009</h5>
									</div>
								</div> */}
								{/* <!-- Navigation Menu ==== --> */}
								<div className="menu-links navbar-collapse collapse justify-content-start" id="menuDropdown">
									<div className="menu-logo">
										<Link to={"/"}><img src={logo} alt=""/></Link>
									</div>
									<ul className="nav navbar-nav">	
										
										<li className="has-mega-menu "><Link to="#">Business Setup<i className="fa fa-chevron-down"></i></Link>
											<ul className="mega-menu">
												<li><Link to="#">Business Setup <br />in India</Link>
													<ul>
														<li><Link to={"/"}><span>Sole Proprietorship Registration</span></Link></li>
														<li><Link to={"/"}><span>Partnership Firm Registration</span></Link></li>
														<li><Link to={"/"}><span>Private Limited Company</span></Link></li>
														<li><Link to={"/"}><span>Public Limited Company</span></Link></li>
														<li><Link to={"/"}><span>One Person Company</span></Link></li>
														<li><Link to={"/"}><span>Nidhi Company Registration</span></Link></li>

													</ul>
												</li>
												<li><Link to="#">Offshore Company <br />Registration</Link>
													<ul>
													<h6 className="menu-adv-title">Asia Pacific</h6>
														<li><Link to={"/"}><span>Hong Kong</span></Link></li>
														<li><Link to={"/"}><span>Singapore</span></Link></li>
													<h6 className="menu-adv-title">Middle East & Africa</h6>
														<li><Link to={"/"}><span>United Arab Emirates (UAE)</span></Link></li>
														<li><Link to={"/"}><span>Mauritius</span></Link></li>
													</ul>
												</li>
												<li><Link to="#">Not for Profit Organisation (NGO) Registration</Link>
													<ul>
														<li><Link to={"/"}><span>Trust Registration</span></Link></li>
														<li><Link to={"/"}><span>Societies Registration</span></Link></li>
														<li><Link to="/"><span>80 G & 12 A Registrations</span></Link></li>
														<li><Link to={"/"}><span>Co-operative Socieites</span></Link></li>
													</ul>
												</li>
												<li><Link to="#">Government Licenses and Registration</Link>
													<ul>
														<li><Link to={"/"}><span>MSME Registration</span></Link></li>
														<li><Link to={"/"}><span>Import & Export Code (IEC)</span></Link></li>
														<li><Link to={"/"}><span>ESI Registration</span></Link></li>
														<li><Link to={"/"}><span>EPF Registration</span></Link></li>
														<li><Link to={"/"}><span>RERA Registration</span></Link></li>
														<li><Link to={"/"}><span>FSSAI License</span></Link></li>
														<li><Link to={"/"}><span>Trademark Registration</span></Link></li>
														<li><Link to={"/"}><span>ISO Registration</span></Link></li>

													</ul>
												</li>
											</ul>
										</li>
										
										{/* <li className="add-mega-menu"><Link to={"#"}>Company Secretarial<i className="fa fa-chevron-down"></i></Link>
											<ul className="sub-menu add-menu">
												<li className="add-menu-left">
													<h5 className="menu-adv-title">Company Secretarial Services</h5>
													<ul>
														<li><Link to={"shop"}><span>Shop</span> </Link></li>
														<li><Link to={"shop-sidebar"}><span>Shop Sidebar</span></Link></li>
														<li><Link to={"shop-cart"}><span>Cart</span></Link></li>
														<li><Link to={"shop-checkout"}><span>Checkout</span></Link></li>
														<li><Link to={"shop-details"}><span>Product Details</span></Link></li>
													</ul>
													<h5 className="menu-adv-title">Our Product</h5>
													<ul>
														<li><Link to={"shop"}><span>Shop</span> </Link></li>
														<li><Link to={"shop-sidebar"}><span>Shop Sidebar</span></Link></li>
														<li><Link to={"shop-cart"}><span>Cart</span></Link></li>
														<li><Link to={"shop-checkout"}><span>Checkout</span></Link></li>
														<li><Link to={"shop-details"}><span>Product Details</span></Link></li>
													</ul>
												</li>
												<li className="add-menu-right">
													<h5 className="menu-adv-title">Conversion and Closures</h5>
													<ul>
														<li><Link to={"shop"}><span>Shop</span> </Link></li>
														<li><Link to={"shop-sidebar"}><span>Shop Sidebar</span></Link></li>
														<li><Link to={"shop-cart"}><span>Cart</span></Link></li>
														<li><Link to={"shop-checkout"}><span>Checkout</span></Link></li>
														<li><Link to={"shop-details"}><span>Product Details</span></Link></li>
													</ul>
												</li>
											</ul>
										</li> */}
										<li className="has-mega-menu "><Link to={"#"}>Company Secretarial <i className="fa fa-chevron-down"></i></Link>
											<ul className="mega-menu">
												<li><Link to={"#"}>Company Secretarial Services</Link>
													<ul>
														<h6 className="menu-adv-title">Secretarial Compliances</h6>
														<li><Link to={"/"}><span>Private/Public Ltd. Annual Compliance</span></Link></li>
														<li><Link to={"/"}><span>Annual Filing of LLP</span></Link></li>
														<li><Link to={"/"}><span>Section 8 Company Compliance</span></Link></li>
														<li><Link to={"/"}><span>NBFC Compliance</span></Link></li>
														<li><Link to={"/"}><span>Nidhi Company Compliance</span></Link></li>
														<li><Link to={"/"}><span>OPC Compliance</span></Link></li>

														<h6 className="menu-adv-title">Activity Based Compliances</h6>
														<li><Link to={"/"}><span>Appointment/Resignation of Directors</span></Link></li>
														<li><Link to={"/"}><span>Change in Share Capital Clause</span></Link></li>
														<li><Link to={"/"}><span>Change in Registered Office Clause</span></Link></li>
														<li><Link to={"/"}><span>Change in Name Clause</span></Link></li>
														<li><Link to={"/"}><span>Change in Object Clause</span></Link></li>
														<li><Link to={"/"}><span>Event Based Compliance of Company</span></Link></li>

													</ul>
												</li>
												{/* <li><Link to={"#"}>Activity Based Compliance</Link>
													<ul>
														<li><Link to={"portfolio-masonry-grid-2"}><span>Appointment/Resignation of Directors</span></Link></li>
														<li><Link to={"portfolio-masonry-grid-3"}><span>Change in Share Capital Clause</span></Link></li>
														<li><Link to={"portfolio-masonry-grid-4"}><span>Change in Registered Office Clause</span></Link></li>
														<li><Link to={"portfolio-masonry-grid-wide"}><span>Change in Name Clause</span></Link></li>
														<li><Link to={"portfolio-masonry-grid-wide"}><span>Change in Object Clause</span></Link></li>
														<li><Link to={"portfolio-masonry-grid-wide"}><span>Event Based Compliance of Company</span></Link></li>
													</ul>
												</li> */}
												<li><Link to={"#"}>Secretarial Audit</Link></li>
												<li><Link to={"#"}>Conversion and Closures</Link>
													<ul>
														<h6 className="menu-adv-title">Business Conversion</h6>
														<li><Link to={"/"}><span>Sole Proprietorship to Private Ltd. Co.</span></Link></li>
														<li><Link to={"/"}><span>Partnership to Private Limited Co.</span></Link></li>
														<li><Link to={"/"}><span>LLP to Private Limited Co.</span></Link></li>
														<li><Link to={"/"}><span>Private Limited Co. to LLP</span></Link></li>
														<h6 className="menu-adv-title">Business Closure</h6>
														<li><Link to={"/"}><span>Winding up Private Limited Company</span></Link></li>
														<li><Link to={"/"}><span>Closure of LLP</span></Link></li>
														<li><Link to={"/"}><span>Revival of Companies Striked off</span></Link></li>
													</ul>
												</li>
													
												<li><Link to={"#"}>Due Diligence</Link></li>																							
											</ul>
										</li>
										<li><Link to={"#"}>Accounting Services<i className="fa fa-chevron-down"></i></Link>
											<ul className="sub-menu">
												<li><Link to={"/"}><span>Cloud Accounting</span> </Link></li>
												<li><Link to={"/"}><span>Onsite Book keeping</span></Link></li>
												<li><Link to={"/"}><span>Payroll Processing</span></Link></li>
												<li><Link to={"/"}><span>Accounting for E-com Seller</span></Link></li>
												<li><Link to={"/"}><span>Offshore Companies Accounting</span></Link></li>
											</ul>
										</li>
										<li><Link to={"#"}>Income Tax<i className="fa fa-chevron-down"></i></Link>
											<ul className="sub-menu">
												<li><Link to={"/"}><span>PAN Application (New / Amendment)</span> </Link></li>
												<li><Link to={"/"}><span>TAN Application (New / Amendment)</span></Link></li>
												<li><Link to={"/"}><span>Income Tax Return Filing of Individuals</span></Link></li>
												<li><Link to={"/"}><span>Corporate Tax Filing</span></Link></li>
												<li><Link to={"/"}><span>Income Tax Refunds</span></Link></li>
												<li><Link to={"/"}><span>Income Tax Notices</span></Link></li>
												<li><Link to={"/"}><span>TDS Return Filing (Original / Revised)</span></Link></li>
												<li><Link to={"/"}><span>TCS Return Filings</span></Link></li>
												<li><Link to={"/"}><span>TDS Filing on Scale of Property</span></Link></li>
												<li><Link to={"/"}><span>Certificate of Foreign Remittance (15CA, 15)</span></Link></li>

											</ul>
										</li>
										<li><Link to={"#"}>GST Services<i className="fa fa-chevron-down"></i></Link>
											<ul className="sub-menu">
												<li><Link to={"/"}><span>GST Registration</span> </Link></li>
												<li><Link to={"/"}><span>GST Modification</span></Link></li>
												<li><Link to={"/"}><span>GST Cancellation</span></Link></li>
												<li><Link to={"/"}><span>LUT Application</span></Link></li>
												<li><Link to={"/"}><span>GST Return Filings (GSTR-1,3B,4)</span></Link></li>
												<li><Link to={"/"}><span>GST Annual Return Filings (GSTR-9 & 9C)</span></Link></li>

											</ul>
										</li>
										<li><Link to={"#"}>E-commerce Business <i className="fa fa-chevron-down"></i></Link>
											<ul className="sub-menu left">
												<li><Link to={"/"}><span>Website Development</span></Link></li>
												<li><Link to={"/"}><span>Product Listings on E-commerce Platforms</span></Link></li>
												<li><Link to={"/"}><span>Accounting & Reconciliation with E-commerce sales</span></Link></li>
												<li><Link to={"/"}><span>Business Setup on Multivendor E-com websites</span></Link></li>
												<li><Link to={"/"}><span>Account Management of Seller Dashboards</span></Link></li>
												<li><Link to={"/"}><span>Digital Signature Certificate</span></Link></li>
											</ul>
										</li>
									</ul>
									<div className="navbar-footer">
										<ul className="nav-social-link">
											<li><a target="_blank" href="https://www.facebook.com/" rel="noreferrer" className="btn btn-primary"><i className="fa fa-facebook"></i></a></li>
											<li><a target="_blank" href="https://www.google.com/" rel="noreferrer" className="btn btn-primary"><i className="fa fa-google-plus"></i></a></li>
											<li><a target="_blank" href="https://www.linkedin.com/" rel="noreferrer" className="btn btn-primary"><i className="fa fa-linkedin"></i></a></li>
											<li><a target="_blank" href="https://twitter.com/" rel="noreferrer" className="btn btn-primary"><i className="fa fa-twitter"></i></a></li>
										</ul>
										<p className="copyright-text">Copyright © 2023. All Rights Reserved.</p>
									</div>
									<div className="menu-close" id="menuClose">
										<i className="ti-close"></i>
									</div>
								</div>
								{/* <!-- Navigation Menu END ==== --> */}
							</div>
						</div>
					</Sticky>
				</header>
				{/* <!-- Header Top END ==== --> */}
			</>
		);
	}
}


export default Navbar;
