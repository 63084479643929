import React, { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import * as THREE from 'three';
import { Decal, Float, OrbitControls, Preload } from '@react-three/drei';
import Loader from '../services/Loader';

const Ball = ({imgUrl}) => {
  const proxiedUrl = imgUrl;
  // Load external texture directly
  const decal = new THREE.TextureLoader().load(proxiedUrl);

  async function renderImage(imgUrl) {

  }
  return (
    <Float speed={2.5} rotationIntensity={1} floatIntensity={2}>
      <ambientLight intensity={0.25} />
      <directionalLight position={[0, 0, 0.05]} />
      {/* Front side of the ball */}
      <mesh castShadow receiveShadow scale={2.75}>
        <icosahedronGeometry args={[1, 2]} />
        <meshStandardMaterial color="aqua" polygonOffset polygonOffsetFactor={-5} flatShading />
        <Decal position={[0, 0, 1]} rotation={[2 * Math.PI, 0, 6.25]} flatShading map={decal} />
      </mesh>
      {/* Back side of the ball */}
      <mesh castShadow receiveShadow scale={2.75} rotation={[0, Math.PI, 0]}>
        <icosahedronGeometry args={[1, 2]} />
        <meshStandardMaterial color="aqua" polygonOffset polygonOffsetFactor={-5} flatShading />
        <Decal position={[0, 0, 1]} rotation={[2 * Math.PI, 0, 6.25]} flatShading map={decal} />
      </mesh>
      
      {/* Top side of the ball */}
      <mesh castShadow receiveShadow scale={2.75} rotation={[Math.PI / 2, 0, 0]}>
        <icosahedronGeometry args={[1, 2]} />
        <meshStandardMaterial color="aqua" polygonOffset polygonOffsetFactor={-5} flatShading />
        <Decal position={[0, 0, 1]} rotation={[2 * Math.PI, 0, 6.25]} flatShading map={decal} />
      </mesh>
      {/* Bottom side of the ball */}
      <mesh castShadow receiveShadow scale={2.75} rotation={[-Math.PI / 2, 0, 0]}>
        <icosahedronGeometry args={[1, 2]} />
        <meshStandardMaterial color="aqua" polygonOffset polygonOffsetFactor={-5} flatShading />
        <Decal position={[0, 0, 1]} rotation={[2 * Math.PI, 0, 6.25]} flatShading map={decal} />
      </mesh>
      
    </Float>
  );
};

const BallCanvas = ({ imgUrl }) => {
  return (
    <Canvas frameloop="always" gl={{ preserveDrawingBuffer: true }}>
      <Suspense fallback={<Loader />}>
        <OrbitControls enableZoom={false} position0={0} />
        <Ball imgUrl={imgUrl} />
      </Suspense>
      <Preload all />
    </Canvas>
  );
};

export default BallCanvas;