import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// Layout
import { Navbar } from '../../components'
import MainSlider from './slider'
// Images
import bgPic5 from '../../images/background/bg5.jpg';
import bgPic6 from '../../images/background/bg6.jpg';
import bgPic7 from '../../images/background/bg7.jpg';

class Index extends Component {
	render(){
		return (
			<>			
				<Navbar />
				
				<div className="page-content bg-white">
					
					<MainSlider />
					
					<div className="content-block" id="content-area">						
						<div className="section-area section-sp3 about-box parallax" style={{backgroundImage: "url("+bgPic5+")", backgroundSize:"cover"}}>
							<div className="container">
								<div className="row align-items-center">
									<div className="col-lg-6 col-md-12 wow fadeIn" data-wow-delay="0.3s">
										<div className="heading-bx style2">
											<h2 className="title-head">Why AFMC?</h2>
											<p className="m-b30">AFMC is a team of highly professional CA, CS, Lawyers who are driving towards integration of technology with traditional practices to cater the need of startups and small business owners in solving legal compliances related to starting and running their business in the fast moving and cost effective world.</p>
											<Link to={"contact-1"} className="btn button-md red">Contact Us</Link>
										</div>
									</div>
									<div className="col-lg-6 col-md-12 wow fadeIn" data-wow-delay="0.6s">
										<div className="row">
											<div className="col-lg-6 col-md-6">
												<div className="feature-container feature-bx3 m-t90">
													<div className="feature-lg text-white m-b10">
														<span className="icon-cell"><i className="flaticon-growth"></i></span> 
													</div>
													<div className="icon-content">
														<h5 className="ttr-tilte">Professionals at One Place</h5>
														<p>We provide range of Professional services of CA’S, CS, CPA’S, CMA, LAWERS, VALUERS Etc. for businesses running in India and outside India like incorporation of different type of entities, GST Registration, ISO certification, Import Export Code, Trademark, Do accounting, Tax filing, Legal and annual compliance(s).</p>
														<Link to={"/services-details"} className="btn-link">View More</Link>
													</div>
												</div>
												{/* <div className="feature-container feature-bx3 blue m-tb30">
													<div className="feature-lg text-white m-b10">
														<span className="icon-cell"><i className="flaticon-rocket"></i></span> 
													</div>
													<div className="icon-content">
														<h5 className="ttr-tilte">Affordable Prices</h5>
														<p>There are many variations of passages of Lorem.</p>
														<Link to={"/services-details"} className="btn-link">View More</Link>
													</div>
												</div> */}
											</div>
											<div className="col-lg-6 col-md-6">
												<div className="feature-container feature-bx3 red m-tb30">
													<div className="feature-lg text-white m-b20">
														<span className="icon-cell"><i className="flaticon-increase"></i></span> 
													</div>
													<div className="icon-content">
														<h5 className="ttr-tilte">Quick Support</h5>
														<p>All the queries of service receivers are answered. Our Experts are always available to you through chats, E-mails and calls.</p>
														{/* <Link to={"/services-details"} className="btn-link">View More</Link> */}
													</div>
												</div>	
												<div className="feature-container feature-bx3 green m-tb30 m-sm-t0">
													<div className="feature-lg text-white m-b20">
														<span className="icon-cell"><i className="flaticon-growth"></i></span> 
													</div>
													<div className="icon-content">
														<h5 className="ttr-tilte">Affordable Prices</h5>
														<p>Our range of professional services is reasonable, Lowest prices. We believe on transparent pricing for all our services, professional fees, government fees and GST.</p>
														{/* <Link to={"/services-details"} className="btn-link">View More</Link> */}
													</div>
												</div>
											</div>
											
										</div>
									</div>
								</div>
							</div>
						</div>
										
					</div>
				</div>				
			</>
		);
	}
}

export default Index;