// src/components/ServiceDetail.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { FIREBASE_DB } from '../../firebase';

const ServiceDetail = () => {
  const { id } = useParams();
  const [service, setService] = useState(null);

  useEffect(() => {
    const fetchService = async () => {
      const docRef = doc(FIREBASE_DB, 'services', id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setService(docSnap.data());
      } else {
        console.log('No such document!');
      }
    };

    fetchService();
  }, [id]);

  return (
    <div className="service-detail">
      {service ? (
        <>
          <h2>{service.name}</h2>
          <p>{service.content}</p>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default ServiceDetail;
